import React from "react";

const Plans = () => {
  return (
    <div className="max-w-[1400px] m-auto py-16 px-2 md:px-5 grid lg:grid-cols-2 gap-4">
      <div className="grid grid-cols-2 grid-rows-6 h-[80vh]">
        <img
          className="row-span-3 object-cover w-full h-full p-2"
          src="https://images.unsplash.com/photo-1432889490240-84df33d47091?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzR8fGJlYWNofGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
        />
        <img
          className="row-span-2 object-cover w-full h-full p-2"
          src="https://images.unsplash.com/photo-1524946274118-e7680e33ccc5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTV8fGJlYWNofGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
        />
        <img
          className="row-span-2 object-cover w-full h-full p-2"
          src="https://images.unsplash.com/photo-1517480448885-d5c53555ba8c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA1fHxiZWFjaHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
        />
        <img
          className="row-span-3 object-cover w-full h-full p-2"
          src="https://images.unsplash.com/photo-1581609836630-9007630f7a7b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTA0fHxiZWFjaHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
        />
        <img
          className="row-span-2 object-cover w-full h-full p-2"
          src="https://images.unsplash.com/photo-1533371452382-d45a9da51ad9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTE4fHxiZWFjaHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
        />
      </div>
      <div className="flex flex-col h-full justify-center">
        <h3 className="text-4xl md:text-6xl font-bold">Planos</h3>
        <p className="pb-6 mt-2">
        Todas nuestras edificaciones futuras llevan un estudio técnico previo realizado por nuestro arquitecto. El comprador puede revisar los planos generales de la obra y de todas las viviendas para su elección y reserva.
        </p>
        <div>
          <button className="bg-black px-4 text-white border-black  hover:shadow-2xl">
          Ver Planos
          </button>
        </div>
      </div>
    </div>
  );
};

export default Plans;
