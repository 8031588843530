import React from "react";

const Rooms2 = () => {
    return (
        <div className="max-w-[1400px] h-[500px] bg-blue-100 mx-auto my-20 pt-16 lg:mb-[20%] md:mb-[35%] 
    md:px-5 px-2 grid lg:grid-cols-3 gap-4">
            <div className="lg:top-20 relative lg:col-span-1 col-span-2">
                <h1 className="text-2xl font-bold">¿Tienes un Solar?</h1>
                <p className="pt-4">
                    Nuestra propuesta: Edificar en tu propiedad a cambio de entregarte viviendas terminadas. Si deseas conocer más sobre Permutas de Solar por Obra Futura, contáctanos.
                </p>
            </div>
            <div className="grid grid-cols-2 col-span-2 gap-2">
                <img className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1631049307264-da0ec9d70304?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
                <img className="row-span-2 object-cover w-full h-full" src="https://images.unsplash.com/photo-1591088398332-8a7791972843?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8aG90ZWwlMjByb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
                <img className="object-cover w-full h-full" src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjByb29tfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" />
            </div>
        </div>
    );
};

export default Rooms2;
