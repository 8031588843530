import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
// import 'swiper/components/navigation/navigation.min.css';
// import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';


import AP1 from "../assets/AP 01 (1).jpg"
import AP2 from "../assets/AP 01 (2).jpg"
import AP3 from "../assets/AP 01 (3).jpg"
import AP4 from "../assets/AP 01 (4).jpg"
import AP5 from "../assets/AP 01 (5).jpg"
import AP6 from "../assets/AP 01 (6).jpg"
import AP7 from "../assets/AP 01 (7).jpg"
import AP8 from "../assets/AP 01 (8).jpg"
import AP9 from "../assets/AP 01 (9).jpg"
import _2AP1 from "../assets/AP 2 (1).jpg"
import _2AP2 from "../assets/AP 2 (2).jpg"
import _2AP3 from "../assets/AP 2 (3).jpg"
import _2AP4 from "../assets/AP 2 (4).jpg"
import _2AP5 from "../assets/AP 2 (5).jpg"
import _2AP6 from "../assets/AP 2 (6).jpg"
import _2AP7 from "../assets/AP 2 (7).jpg"
import _2AP8 from "../assets/AP 2 (8).jpg"
import _2AP9 from "../assets/AP 2 (9).jpg"
import _2AP10 from "../assets/AP 2 (10).jpg"
import _2AP11 from "../assets/AP 2 (11).jpg"

import _3AP1 from "../assets/AP 03 (1).jpg"
import _3AP2 from "../assets/AP 03 (2).jpg"
import _3AP3 from "../assets/AP 03 (3).jpg"
import _3AP4 from "../assets/AP 03 (4).jpg"
import _3AP5 from "../assets/AP 03 (5).jpg"
import _3AP6 from "../assets/AP 03 (6).jpg"
import _3AP7 from "../assets/AP 03 (7).jpg"
import _3AP8 from "../assets/AP 03 (8).jpg"
import _3AP9 from "../assets/AP 03 (9).jpg"
import _3AP10 from "../assets/AP 03 (10).jpg"


import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


// Install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const apartments = [
    {
        id: 1,
        image: AP1,
    },
    {
        id: 2,
        image: AP2,
    },
    {
        id: 3,
        image: AP3,
    },
    {
        id: 4,
        image: AP4,
    },
    {
        id: 5,
        image: AP5,
    },
    {
        id: 6,
        image: AP6,
    },
    {
        id: 7,
        image: AP7,
    },
    {
        id: 8,
        image: AP8,
    },
    {
        id: 9,
        image: AP9,
    },
];


const apartments2 = [
    {
        id: 1,
        image: _2AP1,
    },
    {
        id: 2,
        image: _2AP2,
    },
    {
        id: 3,
        image: _2AP3,
    },
    {
        id: 4,
        image: _2AP4,
    },
    {
        id: 5,
        image: _2AP5,
    },
    {
        id: 6,
        image: _2AP6,
    },
    {
        id: 7,
        image: _2AP7,
    },
    {
        id: 8,
        image: _2AP8,
    },
    {
        id: 9,
        image: _2AP9,
    },
    {
        id: 10,
        image: _2AP10,
    },
    {
        id: 11,
        image: _2AP11,
    },
];




const apartments3 = [
    {
        id: 1,
        image: _3AP1,
    },
    {
        id: 2,
        image: _3AP2,
    },
    {
        id: 3,
        image: _3AP3,
    },
    {
        id: 4,
        image: _3AP4,
    },
    {
        id: 5,
        image: _3AP5,
    },
    {
        id: 6,
        image: _3AP6,
    },
    {
        id: 7,
        image: _3AP7,
    },
    {
        id: 8,
        image: _3AP8,
    },
    {
        id: 9,
        image: _3AP9,
    },
    {
        id: 10,
        image: _3AP10,
    },
];

const Projects = () => {
    const [slide2show, setSlide2show] = useState(3.5);

    useEffect(() => {
        window.scrollTo(0, 0)

        if (window.matchMedia("(min-width: 1500px)").matches) {
            setSlide2show(4)
        } else if (window.matchMedia("(min-width: 1200px)").matches) {
            setSlide2show(3.5)
        } else if (window.matchMedia("(min-width: 700px)").matches) {
            setSlide2show(2.5)
        } else if (window.matchMedia("(min-width: 500px)").matches) {
            setSlide2show(1.9)
        } else {
            setSlide2show(1)
        }
    }, [])

    return (
        <div className='projects'>
            <Navbar />
            {/* <div className="bg-[#122243] py-5 px-5">
                <h1 className='text-center text-white container text-5xl'>VIVIENDAS</h1>
            </div> */}

            <div className="container mx-auto md:px-0 px-3">
                <h1 className='md:text-4xl text-2xl font-bold mt-4 text-start'> VIVIENDA TIPO 1</h1>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={slide2show}
                    navigation
                    pagination={{ clickable: true }}
                    style={{ padding: '20px 20px 40px 20px' }}
                >
                    {apartments.map((apartment) => (
                        <SwiperSlide key={apartment.id} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{
                                width: '300px',
                                border: '1px solid #ddd',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            }}>
                                <img src={apartment.image} alt={apartment.title} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>


                {/* 2nd appartment */}
                <h1 className='md:text-4xl text-2xl font-bold mt-10 text-start'>VIVIENDA TIPO 2</h1>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={slide2show}
                    navigation
                    pagination={{ clickable: true }}
                    style={{ padding: '20px 30px 40px 30px' }}
                >
                    {apartments2.map((apartment) => (
                        <SwiperSlide key={apartment.id} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{
                                width: '300px',
                                border: '1px solid #ddd',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            }}>
                                <img src={apartment.image} alt={apartment.title} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* 3rd appartment */}
                <h1 className='md:text-4xl text-2xl font-bold mt-10 text-start'>VIVIENDA TIPO 3</h1>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={slide2show}
                    navigation
                    pagination={{ clickable: true }}
                    style={{ padding: '20px 30px 40px 30px' }}
                >
                    {apartments3.map((apartment) => (
                        <SwiperSlide key={apartment.id} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{
                                width: '300px',
                                border: '1px solid #ddd',
                                borderRadius: '10px',
                                overflow: 'hidden',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            }}>
                                <img src={apartment.image} alt={apartment.title} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <Footer />
        </div>
    );
};

export default Projects;
