import React from "react";
import { MdTravelExplore } from "react-icons/md";
import { FaTicketAlt, FaHotel } from "react-icons/fa";
const Services = () => {
  return (
    <div className="max-w-[1400px] m-auto py-16 px-2 md:px-5">
      {/* <p className="text-xl text-midnight text-center">¡Unidos mejor!</p> */}
      <h1 className="text-4xl font-bold text-center">
        ¿Quiénes somos?
      </h1>
      <p className="text-center   text-subtext mt-2">Nuestro lema: "Unidos, mejor". En Unidomus te damos la opción de reservar tu vivienda junto a otros compradores, sin adelantos y hasta un 25% más barata que las presentes actualmente en el mercado inmobiliario. Todas nuestras obras futuras cuentan con un estudio técnico previo, y solo iniciamos las gestiones generales cuando se logra un 80% de reservas.</p>
      <div className="grid lg:grid-cols-3 gap-5 mt-10">
        <div className=" p-4 rounded-md">
          <MdTravelExplore size={70} className="text-midnight mx-auto" />
          <div className="text-center mt-3">
            <h2 className="text-2xl font-bold">TRANSPARENCIA</h2>
            <p className="mt-1 text-subtext">
              Se realizarán reuniones para dar conocimiento sobre las gestiones en curso. El comprador estará siempre al tanto de los presupuestos generales, gastos y avances de la obra en todo momento. No recibimos ningún tipo de adelanto monetario hasta la obtención de la Licencia de Obra.
            </p>
          </div>
        </div>
        <div className=" p-4 rounded-md">
          <FaTicketAlt size={70} className="text-midnight  mx-auto" />
          <div className="text-center mt-3">
            <h2 className="text-2xl font-bold">PERSONALIZACIÓN</h2>
            <p className="mt-1 text-subtext">
              Comprar sobre plano te permite la personalización en el diseño y los acabados de tu vivienda. Puedes elegir colores, materiales, y en algunos casos inclusive algunos cambios estructurales dentro de ciertos límites.
            </p>
          </div>
        </div>
        <div className=" p-4 rounded-md">
          <FaHotel size={70} className="text-midnight mx-auto" />
          <div className="text-center mt-3">
            <h2 className="text-2xl font-bold">SEGURO DECENAL</h2>
            <p className="mt-1 text-subtext">
              Los seguros decenales son pólizas contratadas por Unidomus. Su función es proteger a los compradores frente a los riesgos estructurales que podrían afectar las viviendas. Esta cobertura, que se extiende durante diez años, libra a los compradores de asumir los defectos de edificación existentes y obtienen compensaciones en caso de sufrirlas.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
