import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay } from "swiper";

const Hero = () => {
  
  return (
    <div className="w-full h-screen relative">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2750,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: false,
        }}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper w-full mx-auto"
      >
        <SwiperSlide>
          <img
            className="top-0 left-0 w-full h-screen object-cover"
            style={{filter: "brightness(0.7)"}}
            src="https://tupisobarato.com/wp-content/uploads/2023/11/banner-4.jpg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="top-0 left-0 w-full h-screen object-cover"
            style={{filter: "brightness(0.7)"}}
            src="https://tupisobarato.com/wp-content/uploads/2023/11/banner-5.jpg"
          />
        </SwiperSlide>
      </Swiper>
      <div className="absolute top-0 w-full h-full flex flex-col justify-center text-white">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2750,
            disableOnInteraction: false,
          }}
          pagination={false}
          navigation={false}
          modules={[Autoplay]}
          className="mySwiper md:w-2/3 w-11/12 mx-auto"
        >
          <SwiperSlide>
            <div className="w-full flex flex-col items-center text-center">
              <h1 className="font-bold text-4xl md:text-7xl">
                Tu Vivienda Nueva, para Vivir o Invertir
              </h1>
              <p className="max-w-[600px] sm:text-xl py-2 drop-shadow-2xl">
                Hogares asequibles y de calidad. Hasta un 25% más barato.
              </p>
              <button className="bg-white text-black px-4">Reserve Now</button>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-col items-center text-center">
              <h1 className="font-bold text-4xl md:text-7xl">
                Edificaciones Exclusivas
              </h1>
              <p className="max-w-[600px] sm:text-xl py-2 drop-shadow-2xl">
                Proyectos pensados para un grupo reducido de clientes.
              </p>
              <button className="bg-white text-black px-4">Reserve Now</button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Hero;
