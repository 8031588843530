import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const LINKS = [
    {
      location: "/",
      name: "Inicio"
    },
    {
      location: "/projects",
      name: "Projectos"
    },
    {
      location: "/contact",
      name: "Contact"
    }
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative bg-[#122243]">
      <div className="container mx-auto px-4 py-5">
        <div className="flex items-center justify-between">
          <Link to={"/"}>
            <img src={"https://tupisobarato.com/wp-content/themes/amortez/assets/images/logo.png"} className="w-40" alt="Logo" />
          </Link>
          <div className="md:hidden flex items-center">
            <button className='bg-[#3da561] text-white px-3  text-sm py-2 rounded-sm border-none mr-3'
            onClick={()=> navigate("/projects")}>VIEW PROJECTS</button>
            <button onClick={toggleMenu} className=" focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                {isOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" color='white' />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" color='white' />
                )}
              </svg>
            </button>
          </div>
          <div className="hidden md:flex md:items-center">
            {LINKS.map((elem) => (
              <Link key={elem.name} to={elem.location} className={`text-white ${location.pathname === elem.location && "bg-[#3da561] text-white"} px-3  ms-2`}>{elem.name}</Link>
            ))}
            <button className='bg-[#3da561] text-white px-3 py-2 rounded-sm border-none ms-2'>VIEW PROJECTS</button>
          </div>
        </div>
      </div>
      <div className={`fixed top-0 left-0 w-1/2 z-50 flex justify-center  h-full bg-[#122243] shadow-lg transition-transform duration-300 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:hidden`}
      >
        <div className="flex flex-col h-full mt-5">
          {LINKS.map((elem) => (
            <Link
              key={elem.name}
              to={elem.location}
              className={`text-white py-2  text-center font-bold ${location.pathname === elem.location && "bg-[#3da561] text-white"}`}
              onClick={toggleMenu}
            >
              {elem.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;