import React from 'react'
import Hero from "../Components/Hero";
import Navbar from "../Components/Navbar";
import Plans from "../Components/Plans";
import Rooms from "../Components/Rooms";
import Services from "../Components/Services";
import Plans2 from '../Components/Plans2';
import Rooms2 from '../Components/Room2';

const Home = () => {
    return (
        <div>
            <Navbar />
            <Hero />
            <Services />
            <Plans />
            <Rooms />
            <Plans2 />
            <Rooms2 />
         
        </div>
    )
}

export default Home