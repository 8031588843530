import React from 'react'

const Footer = () => {
    return (
        <footer className='md:mt-32 mt-12 bg-black text-white text-center py-3'>
            Unidomus.es&copy; All Right Reserved!
        </footer>
    )
}

export default Footer